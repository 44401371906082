* {
  padding: 0;
  margin-top: 0;
}
/*Navbar*/
.nav-default {
  display: grid;
  background-color: #f8f8f8;
  border-color: black;
  border-width: 0, 0, 1px;
  border-bottom: 1px solid transparent;
  grid-template-columns: repeat(6, auto);
}
.nav-name-left {
  grid-column: 2/4;
  margin-left: 0;
  margin-right: 0;
}
.nav-links-right {
  grid-column: 5/5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

/*Home*/
@media (max-width: 1125px) {
  div.home-description {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .headshot {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1125px) {
  .headshot {
    display: block;
    margin-left: 30%;
    margin-top: 1em;
  }
  .introlinks {
    margin-right: 30%;
  }
}

.home-description {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
}

.headshot {
  max-width: 65%;
}

.introlinks {
  display: block;
  width: 100%;
  text-align: center;
}
.introname {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-family: arial;
}
.introtitle {
  font-size: 20px;
  font-family: arial;
}
.about {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
}
.aboutheader {
  display: grid;
  grid-template-columns: repeat(8, auto);
}
.abouttitle {
  grid-column: 2/6;
}
.abouttext {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 20px;
}

.inline {
  display: inline-block;
}

.infobtn {
  font-size: 15px;
  transition-duration: 0.2s;
  background-color: white;
  color: black;
  border: 2px solid #444;
  margin-top: 1cm;
  padding: 12px 20px;
}

.infobtnmarginmid {
  margin-left: 1em;
  margin-right: 1em;
}

.nav-name-left > a {
  text-decoration: none;
  color: #777;
  font-size: 20px;
}

.nav-link > a {
  text-decoration: none;
  color: #777;
  font-size: 20px;
}

.nav-link > a:hover,
.nav-name-left > a:hover {
  color: black;
}

.nav-default,
.accented,
h1,
h2,
h3,
h4,
h5,
h6,
sup {
  font-family: "Avenir Next", "Lucida Grande", Helvetica, Arial, sans-serif;
}

@media (max-width: 1125px) {
  div.home-description {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .headshot {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1125px) {
  .headshot {
    display: block;
    margin-left: 30%;
    margin-top: 1em;
    /* margin-right:30%; */
  }
  .introlinks {
    margin-right: 30%;
  }
}

.home-description {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
}

.headshot {
  max-width: 65%;
}

.introlinks {
  display: block;
  width: 100%;
  text-align: center;
}
.introname {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-family: arial;
}
.introtitle {
  font-size: 20px;
  font-family: arial;
}

.infobtn:hover {
  background-color: #777;
  color: white;
}

.logo {
  position: relative;
  top: 2px;
  left: 5px;
}

/*Project*/
.tag {
  color: white;
  border-radius: 24px;
  padding: 8px;
  margin: 0.5em;

  border-style: solid;
  outline: none;
}

.tagActive {
  background: #555;
  border-color: #333;
}

.tagInactive {
  background: #bbb;
}

h3 {
  margin-top: 1em;
  margin-bottom: 0em;
}

.leftmargin {
  margin-left: 2em;
}

.updateTag {
  font-size: 15px;
  color: black;
  background: rgb(245, 245, 245);
  border-radius: 10px;
  padding: 10px;
  outline: none;
  border-style: solid;
  border-width: 2px;
  margin-left: 1cm;
}

.updateLogo {
  position: relative;
  top: 2px;
  left: 2px;
}

.updateTagDiff {
  background: #5eb855;
}

.updateTag:hover {
  opacity: 0.7;
}

.updateTagDiff:hover {
  opacity: 0.7;
}

/*Panel*/
.panels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

.panel {
  position: relative;
  height: 6cm;
  width: 10cm;
  background: gainsboro;
  box-shadow: 5px 10px rgb(248, 248, 248);
  margin: 1cm;
  border-style: solid;
  border-width: 2px;
  border-color: gainsboro;
}

.panel:hover {
  opacity: 0.7;
}

.panelImage {
  margin-left: auto;
  margin-right: auto;
  width: 10cm;
  height: 3cm;
  object-fit: cover;
}

.projectDetails {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow: hidden;
  padding: 1em 0.5em 1em 0.5em;
}

.projectDtails::-webkit-scrollbar {
  display: none;
}

.projectTags {
  position: absolute;
  bottom: 0px;
  font-size: 12px;
}

/*Blog*/
.blog {
  margin: 2cm 2cm 2cm 2cm;
}

.blogImage {
  max-width: 100vw;
  max-height: 100vh;
}
